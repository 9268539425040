import { render, staticRenderFns } from "./MyCarAdd.vue?vue&type=template&id=01f3acd4&scoped=true"
import script from "./MyCarAdd.vue?vue&type=script&lang=js"
export * from "./MyCarAdd.vue?vue&type=script&lang=js"
import style0 from "./MyCarAdd.vue?vue&type=style&index=0&id=01f3acd4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f3acd4",
  null
  
)

export default component.exports